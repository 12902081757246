<template>
  <div class='shop_fee'>
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-date-picker v-model="datetime" type="date" placeholder="选择结算时间" @change="handleSelectTime"
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass"
          :header-cell-style="headerCellStyle">
          <el-table-column show-overflow-tooltip prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeCode" label="缴费单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="结算时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="结算周期" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate + '~' + scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="settlementAmount"
            label="结算总额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeAmount" label="服务费金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeAmount" label="减免费用(元)"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="250" fix="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="showDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      datetime: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getTechFeeList()
  },
  methods: {
    // 列表
    getTechFeeList() {
      this.loading = true;
      this.$axios.get(this.$api.getTechFeeList, {
        params: {
          bizType: 1, // 1商超；2供应商
          bizId: this.$store.state.userInfo.shopId,
          startDate: this.datetime || null, // 开始时间
          endDate: this.datetime || null, // 结束时间
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    showDetail(row) {
      console.log(row);
      this.$router.push({ path: '/technicalServiceFee/detail/payServiceFeeDetail', query: { id: row.id } })
    },
    handleSelect(value) {
      this.status = value;
      this.onSearch();
    },
    handleSelectTime(value) {
      this.datetime = value;
      this.onSearch();
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getTechFeeList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e;
      this.getTechFeeList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e;
      this.getTechFeeList();
    },
    reviewRowClass({ columnIndex }) {
      if (columnIndex == 6) {
        return { color: 'red' }
      }
    },
    headerCellStyle({ columnIndex }) {
      if (columnIndex == 6) {
        return { color: 'red' }
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.shop_fee {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 220px;
    }

    .el-input,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
